import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default class PostList extends React.Component {
  reloadPage = reloadUrl => {
    reloadUrl ? (window.location.href = reloadUrl) : null
  }
  render() {
    const { posts, title, categories, category, home } = this.props
    let categoryLinks = []
    let allItems = ''

    {
      posts
        ? (allItems = posts.map(({ node: post }) => (
            <div
              className="column is-one-third-desktop is-half-tablet is-flex"
              key={post.id}
            >
              <div className="tile-inner is-flex">
                <a
                  href={`/${post.slug}`}
                  onClick={
                    post.redirect.redirectUrl
                      ? () => this.reloadPage(post.redirect.redirectUrl)
                      : null
                  }
                  target={post.redirect.redirectUrl ? '_blank' : '_self'}
                  className="text-decoration-none"
                >
                  <h2 className="tile-title">{parse(post.title)}</h2>
                </a>

                {post.featuredImage ? (
                  <a
                    href={`/${post.slug}`}
                    onClick={
                      post.redirect.redirectUrl
                        ? () => this.reloadPage(post.redirect.redirectUrl)
                        : null
                    }
                    target={post.redirect.redirectUrl ? '_blank' : '_self'}
                  >
                    <GatsbyImage
                      image={
                        post.featuredImage?.node?.localFile?.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={post.featuredImage?.node?.altText}
                      title={post.featuredImage?.node?.altText}
                      className="tile-img"
                    />
                  </a>
                ) : null}

                <div
                  className="tile-content"
                  dangerouslySetInnerHTML={{
                    __html: post.content.slice(
                      0,
                      post.content.indexOf('<!--more-->')
                    ),
                  }}
                />

                {post.banners.directLink ? (
                  <a
                    href={post.banners.directLink}
                    target="_blank"
                    className="button has-text-white is-danger is-fullwidth is-medium dotted-border mt-3"
                  >
                    Direct naar het voordeel
                  </a>
                ) : null}
                {post.overnachten?.overnachten ? (
                  <Link
                    to={`/aanbod/${post.title.toLowerCase().replace(' ', '-')}`}
                    className="button has-text-white is-danger is-fullwidth is-medium dotted-border"
                  >
                    Verblijven
                  </Link>
                ) : null}
                <a
                  href={`/${post.slug}`}
                  onClick={
                    post.redirect.redirectUrl
                      ? () => this.reloadPage(post.redirect.redirectUrl)
                      : null
                  }
                  target={post.redirect.redirectUrl ? '_blank' : '_self'}
                  className="button is-fullwidth is-medium"
                >
                  Alle info
                  <FontAwesomeIcon
                    style={{
                      color: '#888',
                      'padding-left': '10px',
                    }}
                    icon={faArrowRight}
                  />
                </a>
              </div>
            </div>
          )))
        : null
    }

    {
      categories
        ? (categoryLinks = categories.map(({ node: categorie }) => {
            if (categorie.name !== category && categorie.count != 0) {
              return (
                <Link
                  key={categorie.id}
                  className="button has-text-white is-danger is-normal"
                  to={`/land/${categorie.slug}/`}
                >
                  {categorie.name}
                </Link>
              )
            }
          }))
        : null
    }

    return (
      <div className="content">
        <div className="has-text-centered">
          {home ? (
            <h2 className="title has-text-centered">{title}</h2>
          ) : (
            <h1 className="title has-text-centered">{title}</h1>
          )}

          <nav className="category-links mb-5">
            {categoryLinks}
            {!home ? (
              <Link
                className="button has-text-white is-danger is-normal"
                to="/"
              >
                Alle
              </Link>
            ) : null}
          </nav>
        </div>

        <div className="columns is-multiline">{allItems}</div>
      </div>
    )
  }
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  data: PropTypes.shape({
    allWordpressCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  fragment PostListFields on WpPost {
    id
    title
    content
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              width: 400
              height: 200
            )
          }
        }
      }
    }
    slug
    categories {
      nodes {
        name
      }
    }
    redirect {
      redirectUrl
    }
    overnachten {
      overnachten
    }
    banners {
      directLink
    }
  }
`
