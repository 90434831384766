import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'

const Category = props => {
  const { data, pageContext } = props
  const { edges: posts } = data.allWpPost
  const { edges: categories } = data.allWpCategory
  const { title: siteTitle, siteUrl } = data.site.siteMetadata
  const { name: category } = pageContext
  const title = category

  return (
    <Layout>
      <Helmet>
        <title>{`Pretparken in ${category} | ${siteTitle}`}</title>
        <link
          rel="canonical"
          href={`${siteUrl}/land/${category.replace('ë', 'e').toLowerCase()}/`}
        />
        <meta name="description" content={`Pretparken in ${category}`} />
        <meta
          name="og:title"
          content={`Pretparken in ${category} | ${siteTitle}`}
        />
        <meta name="og:description" content={`Pretparken in ${category}`} />
      </Helmet>
      <section className="section-post">
        <div className="container">
          <div className="cat-page">
            <PostList
              posts={posts}
              category={category}
              categories={categories}
              home={false}
              title={title}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWpCategory(filter: { wpParent: { node: { name: { eq: "Land" } } } }) {
      edges {
        node {
          name
          slug
          id
          count
        }
      }
    }
  }
`
